import React, { useEffect, useRef, useState } from "react";
import MenuIcon from "../assets/imgs/feather-menu-icon.svg";
import LogoWhite from "../assets/imgs/side-bar-logo/logo@3x.png";
import HeaderCartImage from "../assets/imgs/header-cart-image.svg";
import SearchIcon from "../assets/imgs/search-icon.svg";
import ArrowDown from "../assets/imgs/arrow-down.svg";
import SidebarCircleImage from "../assets/imgs/side-bar-bg-circle.svg";
import LogoutDrawerImage from "../assets/imgs/logout-drawer-image.png";
import Drawer from "@mui/material/Drawer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import firebase from "firebase";
import {
  getAllDataFromCollection,
  getCurrentUser,
  getFilteredData,
  updateDoc,
} from "../utils";
import { Button, Popover, Spin } from "antd";
import { useAuth } from "../contexts/AuthContext";
import { Badge, Divider } from "@mui/material";
import axios from "redaxios";
import TopSlider from "../components/TopSlider";
import { navOptions } from "../constants/nav-options";
import ProfileModal from "./ProfileModal";
import { ArrowDownward, ArrowDropDown, Category, CropSquareSharp, KeyboardArrowDown } from "@mui/icons-material";
import { useCartStore, useUserStore } from "../app/store";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const searchInputRef = useRef(null);
  const { user: storeUser, removeUser } = useUserStore();
  const [specialOfferDetail, setSpecialOfferDetail] = useState({});
  const { storeCart } = useCartStore();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isLogoutDrawerOpen, setIsLogoutIsDrawerOpen] = useState(false);
  const [isCategoriesDropdownOpen, setIsCategoriesDropdownOpen] =
    useState(false);
  const [isNewArrivalDropdownOpen, setIsNewArrivalDropdownOpen] =
    useState(false);
  const params = useParams();
  console.log({ params });
  const [isBrandsDropdownOpen, setIsBrandsDropdownOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const isUserLoggedIn = JSON.parse(localStorage.getItem("isUserLoggedIn"));
  const [cartProductsLength, setCartProductsLength] = useState(0);

  useEffect(() => {
    getCategories();
    getBrandsList();
    fetchSpecialOfferDetail();
    fetchUserDetail();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (searchInputRef?.current?.value) {
      if (!isSearchOpen) {
        setIsSearchOpen(true);
      }
    } else {
      if (isSearchOpen) {
        setIsSearchOpen(false);
      }
    }
  }, [searchInputRef]);

  const fetchUserDetail = async () => {
    try {
      if (currentUser?.email) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        let localCart = JSON.parse(localStorage.getItem("cart")) || [];
        console.log({ localCart });
        localCart?.forEach((product) => {
          let sameProd = userDetail?.cart?.find(
            (prod) => prod?.id === product?.id
          );
          if (!sameProd) {
            userDetail?.cart?.push(product);
          } else {
            let prodToUpdateQuantityIndex = userDetail?.cart?.findIndex(
              (item) => item?.id === product.id
            );
            let totalQuantity =
              userDetail.cart[prodToUpdateQuantityIndex]["quantity"] +
              product?.quantity;
            if (totalQuantity > product?.stock) {
              userDetail.cart[prodToUpdateQuantityIndex]["quantity"] =
                product?.stock;
            } else {
              userDetail.cart[prodToUpdateQuantityIndex]["quantity"] =
                totalQuantity;
            }
          }
        });
        if (localCart?.length != 0) {
          let res = await updateDoc("users", userDetail?.id, {
            cart: userDetail?.cart,
          });
        }
        localStorage.setItem("cart", JSON.stringify([]));
        setCartProductsLength(userDetail?.cart?.length ?? 0);
        setUserDetails(userDetail);
      } else {
        const cartProducts = JSON.parse(localStorage.getItem("cart"));
        if (cartProducts?.length !== 0) {
          setCartProductsLength(cartProducts?.length ?? 0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSpecialOfferDetail = async () => {
    try {
      const data = await getAllDataFromCollection("specialOffer");
      setSpecialOfferDetail(data[0]);
    } catch (error) {}
  };

  const getCategories = async () => {
    const data = await getAllDataFromCollection("categories");
    if (data) {
      setCategories(data);
    }
  };

  const getBrandsList = async () => {
    const data = await getAllDataFromCollection("brands");
    if (data) {
      // Sort the array of objects by name
      data.sort(compareByName);
      setBrands(data);
    }
  };

  const categoriesPopoverContent = (
    <div className="flex flex-col gap-2 p-3">
      <Link to={"/products/fgfhNusLRNa5TeR04KuD"}>
        <p className="m-0 text-lg">Fall/Winter Collection</p>
      </Link>
      <Divider />
      <Link to={"/products/mlGHf5BMrBl42Z7kDGlR"}>
        <p className="m-0 text-lg">Pret Wear</p>
      </Link>
      <Divider />
      <Link to={"/products/oCKAaCCjfTkhNgneZ7mz"}>
        <p className="m-0 text-lg">Formals</p>
      </Link>
      <Divider />
      <Link to={"/products/ugTy6ApcgLEyGeNGqrTt"}>
        <p className="m-0 text-lg">Spring/Summer Collection</p>
      </Link>
    </div>
  );

  const designersPopoverContent = (
    <div className="flex flex-col gap-2 p-3">
      <Link to={"/brand-products/z12XCYhTBBEGn1ckE7td"}>
        <p className="m-0 text-lg">Maria B</p>
      </Link>
      <Divider />
      <Link to={"/brand-products/NfclZG3afcizvaD6S5l6"}>
        <p className="m-0 text-lg">Sana Safinaz</p>
      </Link>
      <Divider />
      <Link to={"/brand-products/TDRiYMZGq0gEgPmGqQGJ"}>
        <p className="m-0 text-lg">Suffuse</p>
      </Link>
      <Divider />
      <Link to={"/brand-products/ulix1ideszx4JGBzSNzU"}>
        <p className="m-0 text-lg">Baroque</p>
      </Link>
      <Divider />
      <Link to={"/brand-products/Y8g7zapCFXSVODq5y8ij"}>
        <p className="m-0 text-lg">Hussnain Rehar</p>
      </Link>
      <Divider />
      <Link to={"/brand-products/U7iuQjqCZwGa9DudPbH1"}>
        <p className="m-0 text-lg">Faiza Saqlain</p>
      </Link>
    </div>
  );

  const compareByName = (a, b) => {
    // Convert names to lowercase before comparison
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1; // a should come before b
    } else if (nameA > nameB) {
      return 1; // b should come before a
    } else {
      return 0; // names are equal, order doesn't matter
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      setIsLogoutIsDrawerOpen(false);
      localStorage.removeItem("cart");
      localStorage.clear();
      removeUser();
      firebase
        .auth()
        .signOut()
        .then((state) => {
          localStorage.setItem("isUserLoggedIn", "false");
          navigate("/login");
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value) {
        navigate("/products?name=" + e.target.value);
      } else {
        navigate("/products");
      }
      console.log(e.target.value);
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="header position-fixed top-0">
        <header className="">
          {!isSearchOpen && (
            <div className="flex gap-5 px-4 py-3 justify-content-between max-lg:flex-row-reverse">
              {!location.pathname.includes("profile-settings") &&
                !location.pathname.includes("checkout") &&
                !location.pathname.includes("order") &&
                !location.pathname.includes("delivery") &&
                !location.pathname.includes("cart") &&
                !location.pathname.includes("wish-list") &&
                !location.pathname.includes("basic-info") &&
                !location.pathname.includes("shipping") &&
                !location.pathname.includes("profile") &&
                !location.pathname.includes("-us") &&
                !location.pathname.includes("privacy-policies") &&
                !location.pathname.includes("refund-and-exchange-policy") &&
                !location.pathname.includes("terms-and-conditions") && (
                  <div className="lg:hidden flex-grow md:flex-grow-0">
                    <div class="relative">
                      <input
                        type="text"
                        className="w-full py-2 pl-5 pr-4 search-input-border"
                        placeholder="Search"
                        // ref={searchInputRef}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onFocus={() => setIsSearchOpen(true)}
                        onBlur={() => {
                          console.log("BLURRRRRRRR");
                          setSearchValue("");
                          setIsSearchOpen(false);
                        }}
                        onKeyDown={handleKeyPress}
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3  
                    items-center  
                    pointer-events-none cursor-pointer hidden sm:flex"
                        onClick={() =>
                          console.log(searchInputRef.current.value)
                        }
                      >
                        <i className="text-gray-400 fas fa-search cursor-pointer"></i>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                )}
              <div className="flex gap-4 align-items-center">
                <Link to="/">
                  <img
                    src={LogoWhite}
                    alt=""
                    height={40}
                    width={123}
                    className="cursor-pointer"
                  />
                </Link>
              </div>
              <div className="flex-1 max-lg:hidden">
                {/* <Link to={"/products"} className=""> */}
                <div class="relative">
                  <input
                    type="text"
                    className="w-full py-2 pl-5 pr-4 search-input-border"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onBlur={() => {
                      console.log("BLURRRRRRRR");
                      setSearchValue("");
                    }}
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3  
                    flex items-center  
                    pointer-events-none cursor-pointer"
                    onClick={() => console.log(searchInputRef.current.value)}
                  >
                    <i className="text-gray-400 fas fa-search cursor-pointer"></i>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <img
                src={MenuIcon}
                alt=""
                height={18}
                width={22}
                className="mr-6 cursor-pointer lg:hidden"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />

              <div className="hidden lg:flex gap-3">
                <Popover
                  placement="bottom"
                  content={categoriesPopoverContent}
                  title={null}
                >
                  <div>
                    <Link to={"/categories"}>
                      <div className="flex items-center justify-center w-full h-full cursor-pointer gap-2">
                        <p className="nav-text ml-2">Categories</p>
                        <KeyboardArrowDown htmlColor="#AAAAAA" sx={{ opacity: "50" }} />
                      </div>
                    </Link>
                  </div>
                </Popover>
                <Popover
                  placement="bottom"
                  content={designersPopoverContent}
                  title={null}
                >
                  <div>
                    <Link to={"/brands"}>
                      <div className="flex items-center justify-center w-full h-full cursor-pointer gap-2">
                        <p className="nav-text ml-2">Designers</p>
                        <KeyboardArrowDown htmlColor="#AAAAAA" sx={{ opacity: "50" }} />
                      </div>
                    </Link>
                  </div>
                </Popover>
                <div>
                  <Link to={"/discounted-products"}>
                    <div className="flex items-center justify-center w-full h-full cursor-pointer">
                      {/* <Category htmlColor="#AAAAAA" sx={{ opacity: "50" }} /> */}
                      <p className="nav-text ml-2">Sale</p>
                    </div>
                  </Link>
                </div>
                {navOptions?.map((navVal) => (
                  <div>
                    <Link
                      to={navVal?.link}
                      className="flex items-center justify-center w-full h-full"
                    >
                      {console.log(currentUser && storeUser?.wishlist?.length,"storeUser",currentUser)}
                      {navVal.title == "Wishlist" && (
                        <Badge
                          badgeContent={currentUser && storeUser?.wishlist?.length}
                          color="primary"
                          size="large"
                        >
                          <img alt={navVal?.title} src={navVal?.icon} />
                        </Badge>
                      )}
                      {navVal.title == "Cart" && (
                        <Badge
                          badgeContent={
                            storeUser?.id
                              ? storeUser?.cart?.length
                              : storeCart?.length
                          }
                          color="primary"
                          size="small"
                        >
                          <img alt={navVal?.title} src={navVal?.icon} />
                        </Badge>
                      )}
                      {navVal.title !== "Wishlist" &&
                        navVal.title !== "Cart" && (
                          <img alt={navVal?.title} src={navVal?.icon} />
                        )}
                      <p className="nav-text ml-2">{navVal?.title}</p>
                    </Link>
                    {/* )} */}
                  </div>
                ))}
              </div>
            </div>
          )}
          {isSearchOpen && (
            <div className="px-4 py-3">
              <div class="relative">
                <input
                  type="text"
                  className="w-full py-2 pl-5 pr-4 search-input-border"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleKeyPress}
                  autoFocus={true}
                  onBlur={() => {
                    console.log("BLURRRRRRRR");
                    setSearchValue("");
                    setIsSearchOpen(false);
                  }}
                />
                <div
                  class="absolute inset-y-0 right-0 pr-3  
                    items-center  
                    pointer-events-none cursor-pointer hidden sm:flex"
                  onClick={() => console.log(searchInputRef.current.value)}
                >
                  <i className="text-gray-400 fas fa-search cursor-pointer"></i>
                </div>
              </div>
            </div>
          )}
        </header>
        <Drawer
          anchor={"left"}
          open={isDrawerOpen}
          className="mx-3"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopRightRadius: "24px",
              borderBottomRightRadius: "24px",
              width: "300px",
            },
          }}
        >
          <div className="h-100 mt-4">
            <div
              className="bg-white position-relative"
              style={{
                zIndex: "9999",
                maxHeight: "calc(100vh - 14rem)",
                overflow: "auto",
              }}
            >
              <div
                className="side-bar-item d-flex justify-content-between align-items-center position-relative new-arrival-side-bar"
                style={{ height: "43.69px" }}
              >
                <Link
                  to={"/new-products"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p>New Arrivals</p>
                </Link>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderLeft: "1px solid #eeeeee",
                  }}
                >
                  <div
                    className="h-100 d-flex justify-content-center cursor-pointer"
                    onClick={() =>
                      setIsNewArrivalDropdownOpen(!isNewArrivalDropdownOpen)
                    }
                  >
                    <img
                      src={ArrowDown}
                      className="cursor-pointer"
                      alt=""
                      style={
                        isNewArrivalDropdownOpen
                          ? { transform: "rotate(90deg)", width: "9px" }
                          : { transform: "rotate(-90deg)", width: "9px" }
                      }
                    />
                    {/* <i class="fa-solid fa-chevron-down"></i> */}
                  </div>
                </div>
              </div>
              {isNewArrivalDropdownOpen && (
                <div style={{ backgroundColor: "#F5F5F5" }}>
                  {categories &&
                    categories?.map((category) => (
                      <Link
                        to={`/products/new/${category?.id}`}
                        key={category?.id}
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <div style={{ borderTop: "1px solid #EEEEEE" }}>
                          <p className="fs-20 px-5 opacity-50 ff-roboto py-2 text-uppercase text-uppercase">
                            {category?.name}
                          </p>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
              <div
                className="side-bar-item d-flex justify-content-between align-items-center position-relative"
                style={
                  { height: "43.69px" }
                  // isNewArrivalDropdownOpen
                  //   ? { height: '90px' }
                  //   : { height: '57px' }
                }
              >
                <Link
                  to={"/new-products"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p>Categories</p>
                </Link>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderLeft: "1px solid #eeeeee",
                  }}
                >
                  <div
                    className="h-100 d-flex justify-content-center cursor-pointer"
                    onClick={() => setIsCategoriesDropdownOpen((prev) => !prev)}
                  >
                    <img
                      src={ArrowDown}
                      className="cursor-pointer"
                      alt=""
                      style={
                        isCategoriesDropdownOpen
                          ? { transform: "rotate(90deg)", width: "9px" }
                          : { transform: "rotate(-90deg)", width: "9px" }
                      }
                    />
                    {/* <i class="fa-solid fa-chevron-down"></i> */}
                  </div>
                </div>
              </div>
              {isCategoriesDropdownOpen && (
                <div style={{ backgroundColor: "#F5F5F5" }}>
                  {categories &&
                    categories?.map((category) => (
                      <Link
                        to={`/products/${category?.id}`}
                        key={category?.id}
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <div style={{ borderTop: "1px solid #EEEEEE" }}>
                          <p className="fs-20 px-5 opacity-50 ff-roboto py-2 text-uppercase">
                            {category?.name}
                          </p>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
              <div
                className="side-bar-item d-flex justify-content-between align-items-center position-relative"
                style={
                  { height: "43.69px" }
                  // isNewArrivalDropdownOpen
                  //   ? { height: '90px' }
                  //   : { height: '57px' }
                }
              >
                <Link to={"/brands"} onClick={() => setIsDrawerOpen(false)}>
                  <p>Designers</p>
                </Link>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    borderLeft: "1px solid #eeeeee",
                  }}
                >
                  <div
                    className="h-100 d-flex justify-content-center cursor-pointer"
                    onClick={() => setIsBrandsDropdownOpen((prev) => !prev)}
                  >
                    <img
                      src={ArrowDown}
                      className="cursor-pointer"
                      alt=""
                      style={
                        isBrandsDropdownOpen
                          ? { transform: "rotate(90deg)", width: "9px" }
                          : { transform: "rotate(-90deg)", width: "9px" }
                      }
                    />
                    {/* <i class="fa-solid fa-chevron-down"></i> */}
                  </div>
                </div>
              </div>
              {isBrandsDropdownOpen && (
                <div style={{ backgroundColor: "#F5F5F5" }}>
                  {brands &&
                    brands?.map((brand) => (
                      <Link
                        to={`/brand-products/${brand?.id}`}
                        key={brand?.id}
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <div style={{ borderTop: "1px solid #EEEEEE" }}>
                          <p className="fs-20 px-5 opacity-50 ff-roboto py-2 text-uppercase">
                            {brand?.name}
                          </p>
                        </div>
                      </Link>
                    ))}
                </div>
              )}
              {specialOfferDetail && specialOfferDetail?.offer && (
                <div className="side-bar-item special-offers">
                  <Link
                    to={`/special-offer-products`}
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    <p>Special Offer</p>
                    <span
                      className="fs-12 ff-roboto"
                      style={{ marginRight: "1rem" }}
                    >
                      {(specialOfferDetail?.offer ?? 0) + "% off"}
                    </span>
                  </Link>
                </div>
              )}
              <div className="side-bar-item">
                <Link
                  to={"/discounted-products"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p style={{ paddingRight: "1rem" }}>Sale</p>
                </Link>
              </div>
              <div className="side-bar-item">
                <Link
                  to={"/products/fgfhNusLRNa5TeR04KuD"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p style={{ paddingRight: "1rem" }}>Fall/Winter collection</p>
                </Link>
              </div>
              <div className="side-bar-item">
                <Link
                  to={"/products/mlGHf5BMrBl42Z7kDGlR"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p style={{ paddingRight: "1rem" }}>Pret Wear</p>
                </Link>
              </div>
              <div className="side-bar-item">
                <Link
                  to={"/products/oCKAaCCjfTkhNgneZ7mz"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p style={{ paddingRight: "1rem" }}>Formals</p>
                </Link>
              </div>
              <div className="side-bar-item">
                <Link
                  to={"/products/ugTy6ApcgLEyGeNGqrTt"}
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <p>Spring/Summer collection</p>
                </Link>
              </div>
              <div className="side-bar-item">
                <Link to={"/contact-us"} onClick={() => setIsDrawerOpen(false)}>
                  <p>Contact Us</p>
                </Link>
              </div>
              <div className="side-bar-item about-us">
                <Link to={"/about-us"} onClick={() => setIsDrawerOpen(false)}>
                  <p>About Us</p>
                </Link>
              </div>
              {currentUser && (
                <div
                  className="side-bar-item about-us"
                  style={{ borderTop: "0" }}
                >
                  <Link
                    to={"/profile-settings"}
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    <p>Account</p>
                  </Link>
                </div>
              )}
            </div>
            <div className="position-absolute bottom-0">
              <img
                src={SidebarCircleImage}
                alt=""
                className="position-absolute bottom-0"
              />
              <div
                className="text-center"
                style={{
                  width: "305px",
                  paddingBottom: "2rem",
                  // bottom: '0',
                  // position: 'absolute',
                  paddingLeft: "1rem",
                  paddingRight: "1.7rem",
                  zIndex: "9",
                  position: "relative",
                }}
              >
                {!currentUser && (
                  <div>
                    <p className="fs-12">
                      Connect your account now for better experience
                    </p>
                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <Link
                        to={"/register"}
                        className="text-decoration-none"
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <button className="bg-gray-filled-btn bg-white">
                          Register
                        </button>
                      </Link>
                      <span className="ff-roboto fw-bold fs-15">OR</span>
                      <Link
                        to={"/login"}
                        className="text-decoration-none"
                        onClick={() => setIsDrawerOpen(false)}
                      >
                        <button
                          className="bg-primary-filled-btn"
                          style={{ width: "100px" }}
                        >
                          Login
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
                {currentUser && (
                  <div className="position-relative" style={{ zIndex: "999" }}>
                    {/* <Link to={"/profile-settings"}>
                      <button
                        className="bg-primary-filled-btn"
                        style={{
                          width: "240px",
                          height: "40px",
                          boxShadow: "0 3px 5px #70707040",
                        }}
                      >
                        PROFILE SETTINGS
                      </button>
                    </Link> */}
                    <button
                      className="bg-gray-filled-btn mt-2"
                      style={{
                        width: "240px",
                        height: "40px",
                        color: "#CC0000",
                        boxShadow: "0 3px 5px #70707040",
                      }}
                      onClick={() => {
                        setIsDrawerOpen(false);
                        setIsLogoutIsDrawerOpen(true);
                      }}
                    >
                      LOGOUT
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
        <Drawer
          anchor={"bottom"}
          open={isLogoutDrawerOpen}
          className="mx-3"
          onClose={() => setIsLogoutIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopRightRadius: "24px",
              borderTopLeftRadius: "24px",
              padding: "2rem",
            },
          }}
        >
          <div className="text-center">
            <h1 className="fs-24 ff-playfair">Logout?</h1>
            <img
              src={LogoutDrawerImage}
              alt=""
              width={150}
              height={150}
              className="my-3 mx-auto"
            />
            <div className="d-flex justify-content-between align-items-center gap-3">
              <button
                className="bg-gray-filled-btn"
                onClick={() => setIsLogoutIsDrawerOpen(false)}
                style={{ width: "160px", height: "40px" }}
              >
                No
              </button>
              <button
                className="bg-primary-filled-btn"
                onClick={handleLogout}
                style={{
                  backgroundColor: "#CC0000",
                  border: "none",
                  width: "160px",
                  height: "40px",
                }}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </Drawer>
      </div>
    </Spin>
  );
};

export default Header;
