import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllDataFromCollection } from "../../utils";
import { Spin } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";

import { Mousewheel, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "./styles.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

SwiperCore.use([Mousewheel, Pagination]);

const HomeCarousel = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    getPopularProducts();
  }, []);

  const getPopularProducts = async () => {
    try {
      setLoading(true);
      let data = await getAllDataFromCollection("products");
      setLoading(false);
      if (data?.length) {
        // Filter for popular products
        const popular = data.filter(product => product.isPopular === true);
        setPopularProducts(popular);
      } else {
        console.log("No products found");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ marginTop: "4rem" }}>
        <Swiper
          pagination={true}
          modules={[Pagination, Mousewheel]}
          className="pb-3 mySwiper"
          slidesPerView={"auto"}
          loop={popularProducts.length > 1}
          spaceBetween={width < 700 ? 0 : 20}
          direction="horizontal"
          mousewheel={{ forceToAxis: true }}
        >
          {popularProducts.length > 0 ? (
            popularProducts.map((product) => (
              <SwiperSlide key={product?.id} className="w-auto ml-2 ">
                <Link to={`/product-details/${product?.id}`}>
                  <img
                    src={product.image[0]} // Assuming the first image in the array is the main product image
                    alt={product.name}
                    style={{ objectFit: "contain", borderRadius: "24px" }}
                    className="rounded-xl h-[300px] sm:h-[400px] md:h-[500px] mb-3"
                  />
                </Link>
              </SwiperSlide>
            ))
          ) : (
            <div>No popular products found.</div>
          )}
        </Swiper>
      </div>
    </Spin>
  );
};

export default HomeCarousel;