import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spin, notification, Image } from "antd";
import {
  fetchSingleDocument,
  getAllDataFromCollection,
  getFilteredData,
  updateDoc,
} from "../utils";
import { useAuth } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import moment from "moment";
import LeftArrow from "../assets/imgs/left-arrow.svg";
import RightArrow from "../assets/imgs/right-arrow.svg";
import { sizeGrid } from "../constants/size-grid";
import useWindowDimensions from "../hooks/useWindowDimensions";
import NewProductCard from "../components/NewProductCard";
import { saveData } from "../utils";
import { toast } from "react-toastify";
import { useCartStore, useProductsStore, useUserStore } from "../app/store";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { currentUser } = useAuth();
  const [isProductAddedToCart, setIsProductAddedToCart] = useState(false);
  const { products: storeProducts } = useProductsStore();
  const { updateUser, user } = useUserStore();
  const [isEnoughStock, setIsEnoughStock] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [initialProductDetails, setInitialProductDetails] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [clothType, setClothType] = useState(null);
  const [price, setPrice] = useState("");
  const [productBrand, setProductBrand] = useState(null);
  const [isCustomSizeMessageDisplayed, setIsCustomSizeMessageDisplayed] =
    useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [sizeChart, setSizeChart] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newProducts, setNewProducts] = useState([]);
  const [reviewDetail, setReviewDetail] = useState({
    comment: "",
    rating: "",
  });
  const { productId } = useParams();
  const [productCartDetails, setProductCartDetails] = useState({
    quantity: 1,
    size: "",
    color: "",
    stitched: "",
    price: 0,
    lining: "like_image",
    customSize: "",
    unstitchedDiscountPrice: 0,
  });

  const { updateCart, storeCart, clearCart } = useCartStore();

  useEffect(() => {
    fetchProductDetails();
    fetchSizeChart();
    fetchUserDetails();
    getAllProducts();

    return () => fetchUserDetails();
  }, [productId]);

  const reload = () => {
    fetchProductDetails();
    fetchSizeChart();
    fetchUserDetails();
    getAllProducts();
  };

  const getCategories = async (prodsList = []) => {
    try {
      let response = await getAllDataFromCollection("categories");
      if (response) {
        const caregoryProducts = prodsList?.map((pro) => {
          const findcategory = response?.find(
            (cat) => cat?.id === pro?.category
          );
          if (findcategory) {
            pro.categoryName = findcategory?.name;
          } else {
            pro.categoryName = "N/A";
          }
          return pro;
        });
        setProducts([...caregoryProducts]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = [...products];
    cProductItems[index] = item;
    setProducts([...cProductItems]);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getAllProducts = async () => {
    try {
      // const data = await getAllDataFromCollection("products");
      const data = [...storeProducts];
      setProducts(data);
      getCategories(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSizeChart = async () => {
    try {
      let response = await getAllDataFromCollection("sizechart");
      setSizeChart(response[0]);
    } catch (error) {
      console.error(error);
    }
  };
  console.log({ sizeChart });

  const fetchUserDetails = async () => {
    try {
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        setUserDetails(data[0]);
        updateUser(data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userDetails && productDetails) {
      let productInCart = userDetails?.cart?.find(
        (item) => item?.id === productId
      );
      if (productInCart) {
        setIsProductAddedToCart(true);
        setProductCartDetails((prev) => ({
          ...prev,
          quantity: productInCart?.quantity,
          color: productInCart?.color,
          size: productInCart?.size,
        }));
      }
    }
    console.log("productDetails:::: ", productDetails);
    console.log("productDetails userDetails:::: ", userDetails);
  }, [userDetails, productDetails]);

  const addToWishList = async () => {
    if (!userDetails?.id) {
      toast.error("Login to add to Wish List", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (userDetails?.wishlist) {
      let wishlist = [...userDetails?.wishlist];
      if (userDetails?.wishlist.includes(productId)) {
        wishlist = wishlist.filter((a) => a !== productId);
        await updateDoc("users", userDetails?.id, { wishlist });
        notification.success({
          message: "Product removed from wishlist",
        });
      } else {
        wishlist.push(productId);
        await updateDoc("users", userDetails?.id, { wishlist });
        notification.success({
          message: "Product added to wishlist",
        });
      }
    } else {
      let wishlist = [productId];
      await updateDoc("users", userDetails?.id, { wishlist });
      notification.success({
        message: "Product added to wishlist",
      });
    }
    getAllProducts();
    fetchUserDetails();
  };

  const onChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      let product = await fetchSingleDocument("products", productId);
      setLoading(false);
      if (product?.cloth_type) {
        fetchProductClothType(product?.cloth_type);
        fetchProductBrand(product?.brand);
        // fetchColors(product?.colors);
      }
      console.log({ product });
      if (!product?.wishList) {
        product.wishList = [];
      }
      console.log("productproduct :: ", product);
      setProductDetails(product);
      setInitialProductDetails(product);
      let price = 0;
      if (product.is_stitched.length === 1) {
        price = product?.is_stitched?.includes("yes")
          ? product?.price
          : product?.unstitchedDiscountPrice;
        setProductCartDetails((prev) => ({
          ...prev,
          stitched:
            product.is_stitched?.length == 1 &&
            product.is_stitched.includes("no")
              ? "unstitched"
              : "stitched",
          price: price,
        }));
      } else {
        setProductCartDetails((prev) => ({ ...prev, price: product?.price }));
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchProductClothType = async (clothTypeId) => {
    try {
      setLoading(true);
      let clothType = await fetchSingleDocument("clothTypes", clothTypeId);
      setClothType(clothType);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchProductBrand = async (productBrand) => {
    try {
      setLoading(false);
      let brandsList = await getAllDataFromCollection("brands");
      // setProductBrand(brand);
      const prodBrand = brandsList.find((brand) => brand.id === productBrand);
      setProductBrand(prodBrand);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAddProductToCart = async () => {
    try {
      if (
        (!productCartDetails?.size || productCartDetails?.customSize) &&
        productCartDetails.stitched !== "unstitched" &&
        productCartDetails.stitched
      ) {
        setIsDrawerOpen(false);
        Swal.fire({
          icon: "warning",
          text: `Please choose size.`,
        });
        return;
      }
      if (!productCartDetails.stitched) {
        setIsDrawerOpen(false);
        Swal.fire({
          icon: "warning",
          text: `Please choose stitching option.`,
        });
        return;
      }
      // if (productCartDetails?.size == "custom") {
      //   notification.info({
      //     message:
      //       "To discuss customised stitching options please fill in the form located on the “contact us” page.",
      //   });
      // }
      setLoading(true);
      let prevCartProds = [];
      if (userDetails && userDetails?.cart) {
        prevCartProds = [...userDetails?.cart];
      } else {
        prevCartProds = JSON.parse(localStorage.getItem("cart")) || [];
      }
      let prodInd = -1,
        data = {};
      if (prevCartProds.length > 0) {
        prodInd = prevCartProds.findIndex((item) => item.id === productId);
      }
      if (prodInd != -1) {
        let prodToUpdate = { ...prevCartProds[prodInd], ...productCartDetails };
        prodToUpdate.quantity = productCartDetails?.quantity;
        prevCartProds.splice(prodInd, 1, prodToUpdate);
      }
      data = {
        cart: [...prevCartProds],
      };
      if (prodInd == -1) {
        data["cart"].push({
          ...productDetails,
          ...productCartDetails,
          quantity: productCartDetails?.quantity,
          id: productId,
          color: productCartDetails?.color,
          stitched: productCartDetails.stitched,
          size: productCartDetails?.customSize
            ? productCartDetails?.customSize
            : productCartDetails?.size,
          prod_price: productCartDetails?.price,
        });
      }
      if (!currentUser?.email) {
        localStorage.setItem("cart", JSON.stringify(data.cart));
        updateCart(data.cart);
        navigate("/cart");
      } else {
        let response = await updateDoc("users", userDetails?.id, data);
        if (response === true) {
          let data = await getFilteredData(
            "users",
            "email",
            "==",
            userDetails?.email
          );
          let userDetail = data[0];
          updateUser(userDetail);
          navigate("/cart");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePostReview = async (e) => {
    try {
      e.preventDefault();
      if (reviewDetail?.comment == "") {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Please add a comment to leave review!",
        });
        return;
      }
      let reviews = [];
      if (productDetails?.reviews?.length > 0) {
        reviews = [...productDetails?.reviews];
      }
      reviews.push({
        ...reviewDetail,
        user_first_name: userDetails.first_name,
        user_last_name: userDetails.last_name,
        datePosted: moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
      });
      setLoading(true);
      let response = await updateDoc("products", productId, {
        reviews: [...reviews],
      });
      if (response) {
        Swal.fire({
          title: "Success!",
          text: "Your review has been posted.",
          icon: "success",
          timer: 3000,
        });
        setReviewDetail({
          size: "",
          color: "",
          comment: "",
          rating: "",
        });
        fetchProductDetails();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // console.log({ productDetails, productCartDetails });

  return (
    <Spin spinning={loading === true}>
      <div className="container mt-[7rem] mb-2">
        <div className="flex flex-col">
          <div className="flex product-detail-bredcrum">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_3031_893)">
                <path
                  d="M7.74155 5.59166C7.41655 5.91666 7.41655 6.44166 7.74155 6.76666L10.9749 10L7.74155 13.2333C7.41655 13.5583 7.41655 14.0833 7.74155 14.4083C8.06655 14.7333 8.59155 14.7333 8.91655 14.4083L12.7416 10.5833C13.0666 10.2583 13.0666 9.73333 12.7416 9.40833L8.91655 5.58333C8.59989 5.26666 8.06655 5.26666 7.74155 5.59166Z"
                  fill="#AAAAAA"
                />
              </g>
              <defs>
                <clipPath id="clip0_3031_893">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            <Link className="product-detail-bredcrum" to="/">
              Home{" "}
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_3031_893)">
                <path
                  d="M7.74155 5.59166C7.41655 5.91666 7.41655 6.44166 7.74155 6.76666L10.9749 10L7.74155 13.2333C7.41655 13.5583 7.41655 14.0833 7.74155 14.4083C8.06655 14.7333 8.59155 14.7333 8.91655 14.4083L12.7416 10.5833C13.0666 10.2583 13.0666 9.73333 12.7416 9.40833L8.91655 5.58333C8.59989 5.26666 8.06655 5.26666 7.74155 5.59166Z"
                  fill="#AAAAAA"
                />
              </g>
              <defs>
                <clipPath id="clip0_3031_893">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            <Link
              className="product-detail-bredcrum"
              to={`/brand-products/${productDetails?.brand}`}
            >
              {productBrand?.name}{" "}
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_3031_893)">
                <path
                  d="M7.74155 5.59166C7.41655 5.91666 7.41655 6.44166 7.74155 6.76666L10.9749 10L7.74155 13.2333C7.41655 13.5583 7.41655 14.0833 7.74155 14.4083C8.06655 14.7333 8.59155 14.7333 8.91655 14.4083L12.7416 10.5833C13.0666 10.2583 13.0666 9.73333 12.7416 9.40833L8.91655 5.58333C8.59989 5.26666 8.06655 5.26666 7.74155 5.59166Z"
                  fill="#333"
                />
              </g>
              <defs>
                <clipPath id="clip0_3031_893">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            <span>{productDetails?.name}</span>
          </div>
          
          <div className="flex w-full gap-2  overflow-x-scroll max-sm:ml-[8px] max-sm:mr-[8px] sm:justify-start mt-[8px] ">
            {productDetails &&
              productDetails?.image?.map((image, index) => (
                <div key={image} className="flex">
                  {/* <div
                    className="popular-brand-card-categories1"
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundRepeat: "no-repeat",
                      height: "540px",
                      width: "360px",
                    }}
                  ></div> */}
                  <Image
                    src={image}
                    width={360}
                    style={{
                      height: "100%",
                      borderRadius: "12px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
          </div>

          <div className="mt-[48px] mb-[48px] max-sm:m-3">
            <div className="flex gap-4 max-lg:flex-col">
              <div className="flex flex-initial flex-col w-full lg:w-1/2">
                <div className="flex max-sm:flex-col max-sm:gap-4">
                  <div className="flex flex-col flex-1 text-start">
                    <p className="product-detail-pn">
                      {productDetails?.name || "-"}
                    </p>
                    <p className="mt-2 product-detail-bn">
                      {productBrand?.name || "-"}
                    </p>
                    <p className="mt-2 product-detail-l">
                      SKU: {productDetails?.stock}
                    </p>
                    <p className="mt-2 product-detail-pn">
                      <span>
                        {productDetails?.is_stitched?.includes("no") &&
                          "£" +
                            Number(
                              productDetails?.unstitchedDiscountPrice || 0
                            ).toFixed(2)}
                        {productDetails?.is_stitched?.length == 2 ? " - " : ""}
                        {productDetails?.is_stitched?.includes("yes") &&
                          "£" + Number(productDetails?.price || 0).toFixed(2)}
                      </span>
                    </p>
                  </div>

                  {/* <div className="flex-1 hidden max-sm:inline">
                    <div className="flex-1 border rounded-[24px] border-[#AAA] border-solid border-1 flex p-[16px] flex-col">
                      <div className="flex gap-2">
                        <div className="flex-1">
                          <label
                            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-state"
                          >
                            Size
                          </label>
                          <div class="relative">
                            <select
                              class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-state"
                              value={productCartDetails?.size}
                              onChange={(e) => {
                                let size = e.target;
                                if (
                                  productCartDetails?.size?.toLowerCase() ===
                                  size?.value?.toLowerCase()
                                ) {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    size: "",
                                  }));
                                } else {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    size: size?.value?.toLowerCase(),
                                  }));
                                }
                              }}
                            >
                              <option value="" disabled selected>
                                Select--
                              </option>
                              <option value="xs">XS</option>
                              <option value="s">S</option>
                              <option value="m">M</option>
                              <option value="l">L</option>
                              <option value="xl">XL</option>
                              <option value="xxl">XXL</option>
                              <option value="xxxl">XXXL</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className="flex-1">
                          <label
                            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-state"
                          >
                            Stitching
                          </label>
                          <div class="relative">
                            <select
                              class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-state"
                              // onChange={e => onChangeValue('country', e.target.value)}
                              // value={addressState?.country}
                              disabled={
                                productDetails?.is_stitched?.length == 1
                              }
                              value={productCartDetails.stitched}
                              onChange={(e) => {
                                let val = e.target.value;
                                if (val === "stitched") {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    price:
                                      prev.lining == "like_image"
                                        ? productDetails?.price
                                        : productDetails?.price + 5,
                                    stitched: val,
                                  }));
                                } else {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    price:
                                      prev.lining == "like_image"
                                        ? productDetails?.unstitchedDiscountPrice
                                        : productDetails?.unstitchedDiscountPrice +
                                          5,
                                    stitched: val,
                                  }));
                                }
                              }}
                            >
                              <option value="" disabled selected>
                                Select--
                              </option>
                              <option value="stitched">Stitched</option>
                              <option value="unstitched">Unstitched</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <label
                          class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Lining
                        </label>
                        <div class="relative">
                          <select
                            class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            // onChange={e => onChangeValue('country', e.target.value)}
                            // value={addressState?.country}
                            value={productCartDetails?.lining}
                            onChange={(e) => {
                              debugger;
                              let val = e.target.value;
                              if (val === "like_image") {
                                let price;
                                if (
                                  productCartDetails?.stitched == "stitched"
                                ) {
                                  price =
                                    productDetails?.price ==
                                    productCartDetails.price
                                      ? productCartDetails.price
                                      : productCartDetails.price - 5;
                                } else {
                                  price =
                                    productDetails?.unstitchedDiscountPrice ==
                                    productCartDetails.price
                                      ? productCartDetails.price
                                      : productCartDetails.price - 5;
                                }
                                setProductCartDetails((prev) => ({
                                  ...prev,
                                  lining: val,
                                  price: price,
                                }));
                              } else {
                                let price;
                                if (
                                  productCartDetails?.stitched == "stitched"
                                ) {
                                  price =
                                    productDetails?.price ==
                                    productCartDetails.price
                                      ? productCartDetails.price + 5
                                      : productCartDetails.price - 5;
                                } else {
                                  price =
                                    productDetails?.unstitchedDiscountPrice ==
                                    productCartDetails.price
                                      ? productCartDetails.price + 5
                                      : productCartDetails.price - 5;
                                }
                                setProductCartDetails((prev) => ({
                                  ...prev,
                                  lining: val,
                                  price,
                                }));
                              }
                            }}
                          >
                            <option value="" disabled selected>
                              Select--
                            </option>
                            <option value="Like image">Like image</option>
                            <option value="Full body & sleeve lining + £5.00">
                              Full body & sleeve lining + £5.00
                            </option>
                          </select>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <p className="mt-3 mb-3 product-detail-pn text-start">
                        <span>
                          £
                          {Number(
                            productDetails?.price *
                              productCartDetails?.quantity || 0
                          ).toFixed(2)}
                        </span>
                      </p>

                      <div className="flex gap-2">
                        <div className="cart-button-pd">
                          <img
                            alt="left arrow"
                            src={LeftArrow}
                            className="cursor-pointer"
                            onClick={(e) => {
                              if (productCartDetails?.quantity > 1) {
                                setProductCartDetails((prev) => ({
                                  ...prev,
                                  quantity: prev?.quantity - 1,
                                }));
                                setIsEnoughStock(true);
                              }
                            }}
                          />
                          <p className="">
                            {productDetails?.stock > 0
                              ? productCartDetails?.quantity
                              : 0}
                          </p>
                          <img
                            alt="right arrow"
                            src={RightArrow}
                            className="cursor-pointer"
                            onClick={(e) => {
                              if (
                                Number(productCartDetails?.quantity) <
                                Number(productDetails?.stock)
                              ) {
                                setProductCartDetails((prev) => {
                                  if (prev.quantity < productDetails?.stock) {
                                    return {
                                      ...prev,
                                      quantity: prev?.quantity + 1,
                                    };
                                  } else return { ...prev };
                                });
                                setIsEnoughStock(true);
                              } else {
                                setIsEnoughStock(false);
                              }
                            }}
                          />
                        </div>
                        <div
                          className="flex-1 cursor-pointer cart-button-pd"
                          onClick={handleAddProductToCart}
                        >
                          <p className="">Add to Cart</p>
                        </div>
                      </div>
                      <div
                        className="flex-1 mt-3 cursor-pointer cart-button-bn"
                        onClick={handleAddProductToCart}
                      >
                        <p className="cart-text">Buy Now</p>
                      </div>
                      <div
                        className="flex-1 mt-3 cart-button-wl cursor-pointer rounded-[24px] border-[#333] border-solid border-[1px] "
                        onClick={() => addToWishList()}
                      >
                        <div className="">
                          {productDetails &&
                          [
                            ...productDetails?.wishList?.filter(
                              (a) => currentUser?.uid === a
                            ),
                          ].length ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_3214_4797)">
                                <path
                                  d="M11.1249 16.7751C10.4916 17.3501 9.51655 17.3501 8.88322 16.7667L8.79155 16.6834C4.41655 12.7251 1.55822 10.1334 1.66655 6.90006C1.71655 5.48339 2.44155 4.12506 3.61655 3.32506C5.81655 1.82506 8.53322 2.52506 9.99988 4.24172C11.4665 2.52506 14.1832 1.81672 16.3832 3.32506C17.5582 4.12506 18.2832 5.48339 18.3332 6.90006C18.4499 10.1334 15.5832 12.7251 11.2082 16.7001L11.1249 16.7751Z"
                                  fill="#333333"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3214_4797">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_3031_4555)">
                                <path
                                  d="M19.66 3.99004C17.02 2.19004 13.76 3.03004 12 5.09004C10.24 3.03004 6.97997 2.18004 4.33997 3.99004C2.93997 4.95004 2.05997 6.57004 1.99997 8.28004C1.85997 12.16 5.29997 15.27 10.55 20.04L10.65 20.13C11.41 20.82 12.58 20.82 13.34 20.12L13.45 20.02C18.7 15.26 22.13 12.15 22 8.27004C21.94 6.57004 21.06 4.95004 19.66 3.99004ZM12.1 18.55L12 18.65L11.9 18.55C7.13997 14.24 3.99997 11.39 3.99997 8.50004C3.99997 6.50004 5.49997 5.00004 7.49997 5.00004C9.03997 5.00004 10.54 5.99004 11.07 7.36004H12.94C13.46 5.99004 14.96 5.00004 16.5 5.00004C18.5 5.00004 20 6.50004 20 8.50004C20 11.39 16.86 14.24 12.1 18.55Z"
                                  fill="#333333"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3031_4555">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>

                        <p className="">Add to Wishlist</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="flex-1 border rounded-[24px] border-[#AAA] border-solid border-1 flex p-[16px] flex-col justify-center">
                    <p className="article-heading text-start">
                      Product Details
                    </p>
                    <div className="flex justify-between border-[#AAA] border-solid border-1 border-b mt-2 pb-1 ml-1">
                      <p className="new-product-category">
                        <span>Brand</span>
                      </p>
                      <p className="new-product-category">
                        {productBrand?.name || "-"}
                      </p>
                    </div>
                    {productCartDetails.stitched == "stitched" && (
                      <div className="flex justify-between border-[#AAA] border-solid border-1 border-b mt-2 pb-1 ml-1 flex-wrap">
                        <p className="new-product-category ">
                          <span>Size</span>
                        </p>
                        <p className="new-product-category text-end">
                          XS, SMALL, MEDIUM, LARGE, XL, XXL, CUSTOM
                        </p>
                      </div>
                    )}
                    <div className="flex justify-between gap-2 mt-2 ml-1">
                      <p className="new-product-category">
                        <span>Lining</span>
                      </p>
                      <p className="new-product-category">
                        {productCartDetails?.lining == "like_image" ||
                        productCartDetails?.lining == "Like Image"
                          ? "Like Image"
                          : productCartDetails?.lining || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-start mt-[24px] border rounded-[24px] border-[#AAA] border-solid border-1 flex p-[16px] flex-col justify-center">
                  <p className="cart-subtotal ">Description</p>
                  <p className="mt-2 product-detail-bredcrum">
                    {productDetails?.description}
                  </p>
                </div>
              </div>
              <div className="flex-1 w-full lg:w-1/2">
                <div className="flex-1 border rounded-[24px] border-[#AAA] border-solid border-1 flex p-[16px] flex-col">
                  {productDetails?.is_stitched?.includes("yes") && (
                    <div className="flex gap-2 flex-col">
                      {productCartDetails.stitched == "stitched" && (
                        <div className="flex-1 flex-grow">
                          <label
                            class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-state"
                          >
                            Size
                          </label>
                          <div class="relative">
                            <select
                              class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8
                              rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-state"
                              value={productCartDetails?.size}
                              onChange={(e) => {
                                let size = e.target;
                                if (e.target.value == "custom") {
                                  if (isCustomSizeMessageDisplayed == false) {
                                    notification.info({
                                      message:
                                        "To discuss customised stitching options, please fill in the form located on the “contact us” page.",
                                    });
                                  }
                                  setIsCustomSizeMessageDisplayed(true);
                                }
                                if (
                                  productCartDetails?.size?.toLowerCase() ===
                                  size?.value?.toLowerCase()
                                ) {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    size: "",
                                  }));
                                } else {
                                  setProductCartDetails((prev) => ({
                                    ...prev,
                                    size: size?.value?.toLowerCase(),
                                  }));
                                }
                              }}
                            >
                              <option value="" disabled selected>
                                Select--
                              </option>
                              <option value="xs">XS</option>
                              <option value="s">SMALL</option>
                              <option value="m">MEDIUM</option>
                              <option value="l">LARGE</option>
                              <option value="xl">XL</option>
                              <option value="xxl">XXL</option>
                              <option value="custom">CUSTOM</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg
                                class="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* {productDetails?.is_stitched?.includes("yes") && */}
                      {/* productDetails?.is_stitched?.length == 1 && ( */}
                      <div className="flex-1 flex-grow">
                        <label
                          class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Stitching Options
                        </label>
                        <div class="relative">
                          <select
                            class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-state"
                            // disabled={productDetails?.is_stitched?.length == 1}
                            value={productCartDetails.stitched}
                            onChange={(e) => {
                              let val = e.target.value;
                              console.log({ productCartDetails });
                              if (val === "stitched") {
                                setProductCartDetails((prev) => ({
                                  ...prev,
                                  price:
                                    prev.lining == "Like image" ||
                                    prev.lining == "like_image"
                                      ? Number(productDetails?.price)
                                      : Number(productDetails?.price) + 5,
                                  stitched: val,
                                }));
                              } else {
                                setProductCartDetails((prev) => ({
                                  ...prev,
                                  price:
                                    prev.lining == "Like image" ||
                                    prev.lining == "like_image"
                                      ? Number(
                                          productDetails?.unstitchedDiscountPrice
                                        )
                                      : Number(
                                          productDetails?.unstitchedDiscountPrice
                                        ) + 5,
                                  stitched: val,
                                }));
                              }
                            }}
                          >
                            <option value="" disabled selected>
                              Select--
                            </option>
                            {productDetails?.is_stitched?.includes("yes") && (
                              <option value="stitched">Stitched</option>
                            )}
                            {productDetails?.is_stitched?.includes("no") && (
                              <option value="unstitched">Unstitched</option>
                            )}
                          </select>
                          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              class="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {/* // )} */}
                    </div>
                  )}

                  <div className="mt-3">
                    <label
                      class="text-start block input-label-text tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-state"
                    >
                      Lining
                    </label>
                    <div class="relative">
                      <select
                        class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-state"
                        value={productCartDetails?.lining}
                        onChange={(e) => {
                          let val = e.target.value;
                          if (val === "Like image") {
                            let price;
                            if (
                              productCartDetails?.stitched == "stitched" ||
                              !productCartDetails?.stitched
                            ) {
                              price =
                                Number(productDetails?.price) ==
                                Number(productCartDetails.price)
                                  ? Number(productCartDetails.price)
                                  : Number(productCartDetails.price) - 5;
                            } else {
                              price =
                                Number(
                                  productDetails?.unstitchedDiscountPrice
                                ) == Number(productCartDetails.price)
                                  ? Number(productCartDetails.price)
                                  : Number(productCartDetails.price) - 5;
                            }
                            setProductCartDetails((prev) => ({
                              ...prev,
                              lining: val,
                              price: price,
                            }));
                          } else {
                            let price;
                            if (
                              productCartDetails?.stitched == "stitched" ||
                              !productCartDetails?.stitched
                            ) {
                              price =
                                Number(productDetails?.price) ==
                                Number(productCartDetails.price)
                                  ? Number(productCartDetails.price) + 5
                                  : Number(productCartDetails.price) - 5;
                            } else {
                              price =
                                Number(
                                  productDetails?.unstitchedDiscountPrice
                                ) == Number(productCartDetails.price)
                                  ? Number(productCartDetails.price) + 5
                                  : Number(productCartDetails.price) - 5;
                            }
                            setProductCartDetails((prev) => ({
                              ...prev,
                              lining: val,
                              price,
                            }));
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select--
                        </option>
                        <option value="Like image">Like image</option>
                        <option value="Full body & sleeve lining + £5.00">
                          {" "}
                          Full body & sleeve lining + £5.00
                        </option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          class="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <p className="mt-3 mb-3 product-detail-pn text-start">
                    <span>
                      £
                      {Number(
                        productCartDetails?.price *
                          productCartDetails?.quantity || 0
                      ).toFixed(2)}
                    </span>
                  </p>

                  <div className="flex gap-2 flex-col sm:flex-row">
                    <div className="cart-button-pd">
                      <img
                        alt="left arrow"
                        src={LeftArrow}
                        className="cursor-pointer"
                        onClick={(e) => {
                          if (productCartDetails?.quantity > 1) {
                            setProductCartDetails((prev) => ({
                              ...prev,
                              quantity: prev?.quantity - 1,
                            }));
                            setIsEnoughStock(true);
                          }
                        }}
                      />
                      <p className="">
                        {productDetails?.stock > 0
                          ? productCartDetails?.quantity
                          : 0}
                      </p>
                      <img
                        alt="right arrow"
                        src={RightArrow}
                        className="cursor-pointer"
                        onClick={(e) => {
                          if (
                            Number(productCartDetails?.quantity) <
                            Number(productDetails?.stock)
                          ) {
                            setProductCartDetails((prev) => {
                              if (prev.quantity < productDetails?.stock) {
                                return {
                                  ...prev,
                                  quantity: prev?.quantity + 1,
                                };
                              } else return { ...prev };
                            });
                            setIsEnoughStock(true);
                          } else {
                            setIsEnoughStock(false);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="flex-1 cursor-pointer cart-button-pd"
                      onClick={handleAddProductToCart}
                    >
                      <p className="">Add to Cart</p>
                    </div>
                  </div>
                  <div
                    className="flex-1 mt-3 cursor-pointer cart-button-bn"
                    onClick={handleAddProductToCart}
                  >
                    <p className="cart-text">Buy Now</p>
                  </div>
                  <div
                    className="flex-1 mt-3 cart-button-wl cursor-pointer rounded-[24px] border-[#333] border-solid border-[1px]"
                    onClick={() => addToWishList()}
                  >
                    {userDetails?.wishlist &&
                    userDetails?.wishlist?.includes(productId) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3214_4797)">
                          <path
                            d="M11.1249 16.7751C10.4916 17.3501 9.51655 17.3501 8.88322 16.7667L8.79155 16.6834C4.41655 12.7251 1.55822 10.1334 1.66655 6.90006C1.71655 5.48339 2.44155 4.12506 3.61655 3.32506C5.81655 1.82506 8.53322 2.52506 9.99988 4.24172C11.4665 2.52506 14.1832 1.81672 16.3832 3.32506C17.5582 4.12506 18.2832 5.48339 18.3332 6.90006C18.4499 10.1334 15.5832 12.7251 11.2082 16.7001L11.1249 16.7751Z"
                            fill="#333333"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3214_4797">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_3031_4555)">
                          <path
                            d="M19.66 3.99004C17.02 2.19004 13.76 3.03004 12 5.09004C10.24 3.03004 6.97997 2.18004 4.33997 3.99004C2.93997 4.95004 2.05997 6.57004 1.99997 8.28004C1.85997 12.16 5.29997 15.27 10.55 20.04L10.65 20.13C11.41 20.82 12.58 20.82 13.34 20.12L13.45 20.02C18.7 15.26 22.13 12.15 22 8.27004C21.94 6.57004 21.06 4.95004 19.66 3.99004ZM12.1 18.55L12 18.65L11.9 18.55C7.13997 14.24 3.99997 11.39 3.99997 8.50004C3.99997 6.50004 5.49997 5.00004 7.49997 5.00004C9.03997 5.00004 10.54 5.99004 11.07 7.36004H12.94C13.46 5.99004 14.96 5.00004 16.5 5.00004C18.5 5.00004 20 6.50004 20 8.50004C20 11.39 16.86 14.24 12.1 18.55Z"
                            fill="#333333"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3031_4555">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    <p className="">Add to Wishlist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`text-start mt-[24px]  flex ${
              width > 700 &&
              "border rounded-[24px] border-[#AAA] border-solid border-1 p-[16px]"
            }  flex-col justify-center`}
          >
            <p className="cart-subtotal mb-3">Size Chart</p>
            <div className="md:w-1/2">
              <Image src={sizeChart?.image} style={{ borderRadius: "12px" }} />
            </div>
            {/* <div
              className={`mt-2 flex border rounded-[24px] border-[#AAA] border-solid border-1 overflow-auto`}
            >
              {sizeGrid.map((size, i) => (
                <div
                  className={`${
                    i !== 8 && "border-r"
                  }  border-[#AAA] border-solid border-1 flex-1`}
                >
                  <div className="bg-black h-[51px] flex justify-center items-center">
                    <p
                      className={`${
                        width < 700 ? "p-1 text-[10px]" : "p-3"
                      } text-white `}
                    >
                      {size.heading}
                    </p>
                  </div>

                  {size.values.map((val, index) => (
                    <div
                      className={`bg-white ${
                        index !== 4 &&
                        "border-b border-[#AAA] border-solid border-1"
                      } flex justify-center items-center`}
                    >
                      <p
                        className={`${
                          width < 700 ? "p-1 py-2 text-[10px]" : "p-3"
                        } text-black `}
                      >
                        {val}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="pb-4 what-is-new max-sm:ml-[8px] max-sm:mr-[8px]">
          <h1 className="pb-[16px] cart-title text-start pt-5">
            Related Products
          </h1>
          <div className="flex w-full gap-2 px-2 m-0 overflow-x-scroll">
            {products?.length &&
              products?.map((product, index) => {
                if (index < 12 && product?.id !== productId)
                  return (
                    <div className="min-w-[200px]" key={product?.id}>
                      <NewProductCard
                        product={product}
                        uid={currentUser?.uid}
                        likeProduct={likeProduct}
                        userDetail={userDetails}
                        fetchProducts={getAllProducts}
                        fetchUserDetails={fetchUserDetails}
                      />
                    </div>
                  );
              })}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProductDetails;
