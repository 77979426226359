import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CartProduct from "../components/CartProduct";
import CloseIcon from "../assets/imgs/close-icon.svg";
import CheckMarkIcon from "../assets/imgs/check-mark-icon.svg";
import PlusIcon from "../assets/imgs/plus-icon.svg";
import MinusIcon from "../assets/imgs/minus-icon.svg";
import { getAllDataFromCollection, getFilteredData, updateDoc } from "../utils";
import { useAuth } from "../contexts/AuthContext";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";

import { Spin } from "antd";
import Swal from "sweetalert2";
import { useCartStore, useProductsStore, useUserStore } from "../app/store";
import { db } from "../firebase";

const Cart = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(null);
  const { storeCart, updateCart: updateStoreCart } = useCartStore();
  const [productDetail, setProductDetail] = useState({});
  const [productColorsArr, setProductColorsArr] = useState([]);
  const [productColors, setProductColors] = useState([]);
  const [cart, setCart] = useState([]);
  // const { storeCart } = useCartStore();
  const [updateCart, setUpdateCart] = useState(false);

  const { user, updateUser } = useUserStore();
  const { products } = useProductsStore();
console.log(user,"user?.")
  useEffect(() => {
    if ( user?.uid||user?.id) {
      setCart(user?.cart);
    } else {
      setCart(storeCart);
    }
  }, [user, storeCart]);

  console.log(cart, "This is cart");

  const fetchColors = async (productColors = []) => {
    try {
      let colors = await getAllDataFromCollection("colors");
      let filtereColors = colors?.filter((color) =>
        productColors?.find((col) => color?.id === col)
      );
      let colorsString = filtereColors?.map((item) => item?.name).join(", ");
      setProductColorsArr(filtereColors);
      setProductColors(colorsString);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        // let data = await getFilteredData(
        //   "users",
        //   "email",
        //   "==",
        //   currentUser?.email
        // );
        // let userDetail = data[0];
        setUserDetails(user);
        setCart(user?.cart);
      } else {
        const cartProducts = JSON.parse(localStorage.getItem("cart"));
        if (cartProducts?.length !== 0) {
          setCart(storeCart);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleRemoveCartProduct = async (id) => {
    try {
      setIsDrawerOpen(false);
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this action!",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        icon: "warning",
      }).then(async (val) => {
        if (val.isConfirmed === true) {
          let prodInd = cart?.findIndex((item) => item?.id === id);
          let userCart = [];
          if (cart?.length != 0) {
            userCart = [...cart];
            userCart?.splice(prodInd, 1);
            setLoading(true);
            let response;
            if (currentUser?.uid) {
              response = await updateDoc("users", user.id, {
                cart: userCart,
              });
              updateUser({ ...user, cart: [...userCart] });
              setCart([...userCart]);
            } else {
              localStorage.setItem("cart", JSON.stringify([...userCart]));
              setCart([...userCart]);
              updateStoreCart(userCart);
              response = true;
            }
            setLoading(false);
          }
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClearAll = async () => {
    try {
      setIsDrawerOpen(false);
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to clear all products from your cart?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
        icon: "warning",
      }).then(async () => {
        setLoading(true);
        let response;
        if (currentUser?.uid) {
          response = await updateDoc("users", user.id, {
            cart: [],
          });
        } else {
          localStorage.removeItem("cart");
          response = true;
        }
        setLoading(false);
        if (response) {
          Swal.fire({
            title: "Success!",
            text: "Cart has been cleared.",
            icon: "success",
            timer: 2000,
          }).then(() => {
            if (!currentUser?.uid) {
              setCart([]);
            }
            // fetchUserDetails();
          });
        }
      });
    } catch (error) {}
  };

  const handleUpdateCartProduct = async () => {
    try {
      setIsDrawerOpen(false);
      // let prodInd = cart?.findIndex(
      //   (item) => item?.brand === productDetail?.brand
      // )
      let cartProducts = [...cart];
      // cartProducts?.splice(prodInd, 1, productDetail)
      // setLoading(true)
      let response;
      if (currentUser?.uid) {
        response = await updateDoc("users", user.id, {
          cart: cartProducts,
          payable_amount: cart
            ?.reduce(
              (sum, { prod_price, quantity }) =>
                sum + Number(prod_price) * quantity,
              0
            )
            ?.toFixed(2),
        });
      } else {
        localStorage.setItem("cart", JSON.stringify(cartProducts));
        updateStoreCart(cartProducts);
        response = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const plusProduct = (id, stock) => {
    let updateCart = cart;
    updateCart?.map((ct) => {
      const quantity = ct.quantity + 1;
      if (ct.id === id && quantity <= stock) {
        ct.quantity = quantity;
      }
      return ct;
    });
    setCart([...updateCart]);
    handleUpdateCartProduct();
  };

  const minusProduct = (id) => {
    let updateCart = cart;
    updateCart?.map((ct) => {
      const quantity = ct.quantity - 1;
      if (ct.id === id && quantity > 0) {
        ct.quantity = quantity;
      }
      return ct;
    });
    setCart([...updateCart]);
    handleUpdateCartProduct();
  };

  return (
    <>
      <div className="px-4 mt-[8rem] sm:ml-[80px] sm:mr-[80px] mb-[48px]">
        <Spin spinning={loading}>
          <p className="cart-header">Cart</p>
          {cart?.length > 0 && (
            <div style={{ marginTop: "48px" }}>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {cart?.map((product, index) => (
                  <CartProduct
                    page={"cart"}
                    product={product}
                    handleRemoveCartProduct={handleRemoveCartProduct}
                    plusProduct={plusProduct}
                    minusProduct={minusProduct}
                    key={product.id}
                  />
                ))}
              </div>
              <div className="flex justify-center mt-[48px]">
                <Link
                  to="/delivery-information"
                  className="cursor-pointer cart-order-btn"
                >
                  <p>Place Order</p>
                </Link>
              </div>
            </div>
          )}

          {(!cart || cart?.length == 0) && !loading && (
            <div className="text-center" style={{ marginTop: "5rem" }}>
              {/* <h1 className="fw-bold ff-roboto">Cart is Empty!</h1> */}
              <div className="text-center  mt-4">
              <img src={NoProductsFoundPlaceHolder} />
            </div>
            </div>
          )}
        </Spin>
        <Drawer
          anchor={"bottom"}
          open={isDrawerOpen}
          className="mx-3"
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            style: {
              borderTopLeftRadius: "24px",
              borderTopRightRadius: "24px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            },
          }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fs-24 ff-playfair fw-bold">Edit Product</h1>
              <img
                src={CloseIcon}
                alt=""
                height={32}
                width={32}
                className="cursor-pointer"
                onClick={() => setIsDrawerOpen(false)}
              />
            </div>
            <div className="gap-2 p-2 mt-4 product-card gray-border-12-5px d-flex align-items-center">
              <img
                src={productDetail?.image && productDetail?.image[0]}
                alt=""
                height={50}
                width={50}
                className="br-12"
              />
              <div>
                <p className="fs-20 ff-roboto">{productDetail?.name}</p>
                <p className="ff-roboto fw-bold fs-20">
                  £{productDetail?.price}
                </p>
              </div>
            </div>
            {productDetail?.stitched === "stitched" && (
              <div className="flex-wrap mt-3 d-flex justify-content-between align-items-center">
                <div className="gap-1 product-size-labels d-flex product-details">
                  {productDetail?.sizes?.map((size) => (
                    <div
                      key={size}
                      className="cursor-pointer position-relative"
                      style={
                        productDetail?.size?.toLowerCase() ===
                        size?.toLowerCase()
                          ? { backgroundColor: "#000" }
                          : {}
                      }
                      onClick={(e) => {
                        if (
                          productDetail?.size?.toLowerCase() ===
                          size?.toLowerCase()
                        ) {
                          // setProductDetail((prev) => ({
                          //   ...prev,
                          //   size: "",
                          // }))
                        } else {
                          setProductDetail((prev) => ({
                            ...prev,
                            size: size?.toLowerCase(),
                          }));
                        }
                      }}
                    >
                      {productDetail?.size?.toLowerCase() ===
                        size?.toLowerCase() && (
                        <img
                          src={CheckMarkIcon}
                          className="position-absolute"
                          style={{ top: "-4px", right: "-5px" }}
                        />
                      )}
                      <span
                        className={
                          productDetail?.size?.toLowerCase() ===
                          size?.toLowerCase()
                            ? "text-white opacity-100"
                            : ""
                        }
                      >
                        {size?.toUpperCase()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <div className="gap-2 d-flex align-items-center">
                <p className="fs-20 ff-roboto">Quantity: </p>
                <div
                  className="gap-1 gray-border-12-5px fs-21 d-flex justify-content-between align-items-center br-50"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="br-50 bg-gray add-quantity-icon">
                    <img
                      src={PlusIcon}
                      alt=""
                      height={16}
                      width={16}
                      className="cursor-pointer"
                      onClick={(e) => {
                        setProductDetail((prev) => {
                          if (prev.quantity < productDetail?.stock) {
                            return {
                              ...prev,
                              quantity: prev?.quantity + 1,
                            };
                          } else return { ...prev };
                        });
                      }}
                    />
                  </div>
                  <input
                    type="number"
                    className="text-center fs-20 ff-roboto"
                    style={{ width: "31px", outline: "none" }}
                    value={productDetail?.quantity}
                    min={0}
                    max={productDetail?.stock}
                    onChange={(e) =>
                      setProductDetail((prev) => ({
                        ...prev,
                        quantity: e.target.valueAsNumber,
                      }))
                    }
                  />
                  <div className="br-50 bg-gray remove-quantity-icon">
                    <img
                      src={MinusIcon}
                      alt=""
                      className="cursor-pointer"
                      height={16}
                      width={16}
                      onClick={(e) => {
                        if (productDetail?.quantity > 1) {
                          setProductDetail((prev) => ({
                            ...prev,
                            quantity: prev?.quantity - 1,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <p className="fw-bold fs-35 ff-roboto color-primary">
                £{productDetail?.price}
              </p>
            </div>
            <div className="gap-3 mt-3 d-flex justify-content-between align-items-center">
              <button
                className="bg-gray-filled-btn"
                onClick={handleRemoveCartProduct}
              >
                Remove
              </button>
              <Spin spinning={loading}>
                <button
                  className="bg-primary-filled-btn"
                  style={{ width: "200px", height: "40px" }}
                  onClick={handleUpdateCartProduct}
                >
                  Update
                </button>
              </Spin>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Cart;
