import React from "react";
import { saveData, updateDoc } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Image, notification } from "antd";
import { useUserStore } from "../app/store";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NewProductCard = ({
  product,
  uid = "",
  likeProduct,
  index,
  categories,
  userDetail,
  fetchProducts,
  fetchUserDetails,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));

  const getImageHeight = () => {
    if (isXs) return 300;
    if (isSm) return 400;
    if (isMd) return 450;
    if (isLg) return 500;
    return 500; // default for xl and up
  };
  let price = 0;
  let originalPrice = 0;
  if (product.is_stitched.includes("yes")) {
    price = product.price;
    originalPrice = product.originalPrice;
  } else if (!product.is_stitched.includes("yes")) {
    price = product.unstitchedDiscountPrice;
    originalPrice = product.unstitchedPrice;
  }
  const { user } = useUserStore();
  let category = categories?.find((item) => item.id == product.category)?.name;
  const addToWishList = async () => {
    if (!uid) {
      toast.error("Login to add to Wish List", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (userDetail?.wishlist) {
      let wishlist = [...userDetail?.wishlist];
      if (userDetail?.wishlist.includes(product.id)) {
        wishlist = wishlist.filter((a) => a !== product.id);
        await updateDoc("users", userDetail?.id, { wishlist });
        notification.success({
          message: "Product removed from wishlist",
        });
      } else {
        wishlist.push(product.id);
        await updateDoc("users", userDetail?.id, { wishlist });
        notification.success({
          message: "Product added to wishlist",
        });
      }
    } else {
      let wishlist = [product.id];
      await updateDoc("users", userDetail?.id, { wishlist });
      notification.success({
        message: "Product added to wishlist",
      });
    }
    fetchProducts();
    fetchUserDetails();
  };

  return (
    <div className="mb-2 flex justify-between flex-col h-100" key={product?.id}>
      <div to={`/product-details/${product?.id}`} class="flex-1 flex-grow">
        <Image
          src={product?.image[0]}
          alt="dd"
          height={getImageHeight()}

          // height={500}
          className="new-product-image h-[300px] md:h-[500px] lg:h-[400px] xl:h-[500px]"
        />
      </div>
      <Link  to={`/product-details/${product?.id}`} className="p-2">
        <p className="mt-1 d-flex align-items-center new-product-name text-left">
          {product?.name}
        </p>
        <p className="mt-1 text-capitalize d-flex text-start new-product-category ">
          {product?.categoryName?.substring(0, 11) || category}
        </p>
        <div className="mt-1 d-flex justify-content-between align-items-center">
          <p className="new-product-price ">
            {product?.is_stitched?.includes("yes") &&
              "£" +
                Number(
                  product.unstitchedDiscountPrice ||
                    Number(price || 0).toFixed(2)
                ).toFixed(2)}
            {product?.is_stitched?.length == 2 ? " - " : ""}
            {product?.is_stitched?.includes("no") &&
              "£" + Number(price || 0).toFixed(2)}
          </p>
          {product?.is_discounted && (
            <div className="gap-2 px-2 discount-badge d-flex">
              <span className="original-price text-decoration-line-through">
                £{originalPrice}
              </span>
              <span className="discounted-price">-{product?.discount}%</span>
            </div>
          )}
        </div>
        <div className="flex pt-2">
          <Link to={`/product-details/${product?.id}`}>
            <div class="new-product-button flex-1 mr-1">
              <span className="select-option-button">Select options</span>
            </div>
          </Link>
          <div
            class="new-product-button cursor-pointer"
            onClick={addToWishList}
          >
            {user?.wishlist && user?.wishlist.includes(product?.id) ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3214_3839)">
                  <path
                    d="M11.1249 16.7751C10.4916 17.3501 9.51655 17.3501 8.88322 16.7667L8.79155 16.6834C4.41655 12.7251 1.55822 10.1334 1.66655 6.90006C1.71655 5.48339 2.44155 4.12506 3.61655 3.32506C5.81655 1.82506 8.53322 2.52506 9.99988 4.24172C11.4665 2.52506 14.1832 1.81672 16.3832 3.32506C17.5582 4.12506 18.2832 5.48339 18.3332 6.90006C18.4499 10.1334 15.5832 12.7251 11.2082 16.7001L11.1249 16.7751Z"
                    fill="#333333"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3214_3839">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3414_1560)">
                  <path
                    d="M16.3832 3.32506C14.1832 1.82506 11.4665 2.52506 9.99985 4.24172C8.53319 2.52506 5.81652 1.81672 3.61652 3.32506C2.44985 4.12506 1.71652 5.47506 1.66652 6.90006C1.54985 10.1334 4.41652 12.7251 8.79152 16.7001L8.87485 16.7751C9.50819 17.3501 10.4832 17.3501 11.1165 16.7667L11.2082 16.6834C15.5832 12.7167 18.4415 10.1251 18.3332 6.89172C18.2832 5.47506 17.5499 4.12506 16.3832 3.32506ZM10.0832 15.4584L9.99985 15.5417L9.91652 15.4584C5.94985 11.8667 3.33319 9.49172 3.33319 7.08339C3.33319 5.41672 4.58319 4.16672 6.24985 4.16672C7.53319 4.16672 8.78319 4.99172 9.22485 6.13339H10.7832C11.2165 4.99172 12.4665 4.16672 13.7499 4.16672C15.4165 4.16672 16.6665 5.41672 16.6665 7.08339C16.6665 9.49172 14.0499 11.8667 10.0832 15.4584Z"
                    fill="#333333"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3414_1560">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </Link>
      <ToastContainer />
    </div>
  );
};

export default NewProductCard;
