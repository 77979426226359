import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditProfile from "./EditProfile";
import { useUserStore } from "../app/store";
import { useAuth } from "../contexts/AuthContext";

const Profile = ({
  handleClose,
  handleLogout,
  isLogin,
  onChangeAuthText,
  isDesktop,
  setOpen,
}) => {
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const { removeUser } = useUserStore();
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();

  
  return (
    <>
      {currentUser ? (
        <div className="flex flex-col">
          <div className="flex justify-center align-items-center">
            <h1 className="cart-header text-start">Profile</h1>
          </div>
          <Link to="/placed-orders" onClick={() => handleClose(false)}>
            <div className="flex p-3 mt-6 cursor-pointer profile-modal-button">
              <div className="flex gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3214_4893)">
                    <path
                      d="M19.5 8H17V6C17 4.9 16.1 4 15 4H3C1.9 4 1 4.9 1 6V15C1 16.1 1.9 17 3 17C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H22C22.55 17 23 16.55 23 16V12.67C23 12.24 22.86 11.82 22.6 11.47L20.3 8.4C20.11 8.15 19.81 8 19.5 8ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM19.5 9.5L21.46 12H17V9.5H19.5ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3214_4893">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="profile-modal-text">Placed Orders</p>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.29006 15.88L13.1701 12L9.29006 8.11998C8.90006 7.72998 8.90006 7.09998 9.29006 6.70998C9.68006 6.31998 10.3101 6.31998 10.7001 6.70998L15.2901 11.3C15.6801 11.69 15.6801 12.32 15.2901 12.71L10.7001 17.3C10.3101 17.69 9.68006 17.69 9.29006 17.3C8.91006 16.91 8.90006 16.27 9.29006 15.88Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </Link>
          <Link to="/orders-history" onClick={() => handleClose(false)}>
            <div className="flex p-3 mt-2 cursor-pointer profile-modal-button">
              <div className="flex gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3214_4180)">
                    <path
                      d="M13.26 3.00007C8.17003 2.86007 4.00003 6.95007 4.00003 12.0001H2.21003C1.76003 12.0001 1.54003 12.5401 1.86003 12.8501L4.65003 15.6501C4.85003 15.8501 5.16003 15.8501 5.36003 15.6501L8.15003 12.8501C8.46003 12.5401 8.24003 12.0001 7.79003 12.0001H6.00003C6.00003 8.10007 9.18003 4.95007 13.1 5.00007C16.82 5.05007 19.95 8.18007 20 11.9001C20.05 15.8101 16.9 19.0001 13 19.0001C11.39 19.0001 9.90003 18.4501 8.72003 17.5201C8.32003 17.2101 7.76003 17.2401 7.40003 17.6001C6.98003 18.0201 7.01003 18.7301 7.48003 19.0901C9.00003 20.2901 10.91 21.0001 13 21.0001C18.05 21.0001 22.14 16.8301 22 11.7401C21.87 7.05007 17.95 3.13007 13.26 3.00007ZM12.75 8.00007C12.34 8.00007 12 8.34007 12 8.75007V12.4301C12 12.7801 12.19 13.1101 12.49 13.2901L15.61 15.1401C15.97 15.3501 16.43 15.2301 16.64 14.8801C16.85 14.5201 16.73 14.0601 16.38 13.8501L13.5 12.1401V8.74007C13.5 8.34007 13.16 8.00007 12.75 8.00007Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3214_4180">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="profile-modal-text">Orders History</p>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.29006 15.88L13.1701 12L9.29006 8.11998C8.90006 7.72998 8.90006 7.09998 9.29006 6.70998C9.68006 6.31998 10.3101 6.31998 10.7001 6.70998L15.2901 11.3C15.6801 11.69 15.6801 12.32 15.2901 12.71L10.7001 17.3C10.3101 17.69 9.68006 17.69 9.29006 17.3C8.91006 16.91 8.90006 16.27 9.29006 15.88Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </Link>
          <Link to="/basic-info">
            <div
              className="flex p-3 mt-2 cursor-pointer profile-modal-button"
              // onClick={() => setIsProfileEdit(true)}
            >
              <div className="flex gap-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3214_4610)">
                    <path
                      d="M3 17.4601V20.5001C3 20.7801 3.22 21.0001 3.5 21.0001H6.54C6.67 21.0001 6.8 20.9501 6.89 20.8501L17.81 9.94006L14.06 6.19006L3.15 17.1001C3.05 17.2001 3 17.3201 3 17.4601ZM20.71 7.04006C21.1 6.65006 21.1 6.02006 20.71 5.63006L18.37 3.29006C17.98 2.90006 17.35 2.90006 16.96 3.29006L15.13 5.12006L18.88 8.87006L20.71 7.04006Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3214_4610">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="profile-modal-text">Edit Profile</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.29006 15.88L13.1701 12L9.29006 8.11998C8.90006 7.72998 8.90006 7.09998 9.29006 6.70998C9.68006 6.31998 10.3101 6.31998 10.7001 6.70998L15.2901 11.3C15.6801 11.69 15.6801 12.32 15.2901 12.71L10.7001 17.3C10.3101 17.69 9.68006 17.69 9.29006 17.3C8.91006 16.91 8.90006 16.27 9.29006 15.88Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </Link>
          <div
            className="flex p-3 mt-2 cursor-pointer profile-modal-button"
            onClick={() => {
              // handleClose(false)
              localStorage.removeItem("cart");
              localStorage.clear();
              removeUser();
              logout();
              handleLogout();
              navigate("/");
            }}
          >
            <div className="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
              >
                <path
                  d="M5.2825 4.7C5.6725 5.09 5.6725 5.71 5.2825 6.1L3.3825 8H12.5825C13.1325 8 13.5825 8.45 13.5825 9C13.5825 9.55 13.1325 10 12.5825 10H3.3825L5.2825 11.9C5.6725 12.29 5.6725 12.91 5.2825 13.3C4.8925 13.69 4.2725 13.69 3.8825 13.3L0.2925 9.71C-0.0975 9.32 -0.0975 8.69 0.2925 8.3L3.8825 4.7C4.2725 4.31 4.8925 4.31 5.2825 4.7ZM18 16H11C10.45 16 10 16.45 10 17C10 17.55 10.45 18 11 18H18C19.1 18 20 17.1 20 16V2C20 0.9 19.1 0 18 0H11C10.45 0 10 0.45 10 1C10 1.55 10.45 2 11 2H18V16Z"
                  fill="#FF0000"
                />
              </svg>
              <p className="profile-modal-text profile-modal-text-red">
                Logout
              </p>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.29006 15.88L13.1701 12L9.29006 8.11998C8.90006 7.72998 8.90006 7.09998 9.29006 6.70998C9.68006 6.31998 10.3101 6.31998 10.7001 6.70998L15.2901 11.3C15.6801 11.69 15.6801 12.32 15.2901 12.71L10.7001 17.3C10.3101 17.69 9.68006 17.69 9.29006 17.3C8.91006 16.91 8.90006 16.27 9.29006 15.88Z"
                fill="#333333"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          {isDesktop ? (
            <div className="flex justify-center align-items-center">
              <h1 className="cart-header text-start">Profile</h1>
            </div>
          ) : (
            <div className="flex justify-between align-items-center">
              <h1 className="cart-header text-start">Profile</h1>
              <div
                className="cursor-pointer new-product-button"
                onClick={() => handleClose(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clip-path="url(#clip0_3214_898)">
                    <path
                      d="M15.2501 4.75843C14.9251 4.43343 14.4001 4.43343 14.0751 4.75843L10.0001 8.8251L5.9251 4.7501C5.6001 4.4251 5.0751 4.4251 4.7501 4.7501C4.4251 5.0751 4.4251 5.6001 4.7501 5.9251L8.8251 10.0001L4.7501 14.0751C4.4251 14.4001 4.4251 14.9251 4.7501 15.2501C5.0751 15.5751 5.6001 15.5751 5.9251 15.2501L10.0001 11.1751L14.0751 15.2501C14.4001 15.5751 14.9251 15.5751 15.2501 15.2501C15.5751 14.9251 15.5751 14.4001 15.2501 14.0751L11.1751 10.0001L15.2501 5.9251C15.5668 5.60843 15.5668 5.0751 15.2501 4.75843Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3214_898">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          )}

          <div className="mt-[48px] flex flex-col">
            <p className="text-center radio-text">Nothing to show here!</p>
            <p className="text-center radio-text">
              Please create an account or login to view your placed orders and
              history of orders.{" "}
            </p>
          </div>
          <div
            class="new-product-button flex-1 mr-2 bg-black text-white p-3 mt-6 cursor-pointer"
            onClick={() => navigate("/register")}
          >
            Create an Account
          </div>

          <div
            onClick={() => navigate("/login")}
            class="new-product-button flex-1 mr-2  p-3 mt-2 text-black cursor-pointer"
          >
            Login
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
