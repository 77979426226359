import { useEffect, useState } from "react";
import CategoryProductCard from "../../components/CategoryProductCard";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { Spin } from "antd";
// import SpringSummerCollectionImage from "../../assets/imgs/SPRING:SUMMER COLLECTION.jpeg";
import PretWearImage from "../../assets/imgs/PRET WEAR.jpeg";
import FormalsImage from "../../assets/imgs/FORMALS.jpeg";
// import FALLWINTERCOLLECTIONImage from "../../assets/imgs/FALL:WINTER COLLECTION.jpeg";
import { useCategoriesStore } from "../../app/store";

const Categories = () => {
  // const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(null);
  const { categories } = useCategoriesStore();

  return (
    <Spin spinning={loading}>
      <div className="pb-4 categories">
        <h1 className="pt-[32px] pb-[16px] main-heading">Categories</h1>
        <div className="px-2 m-0 row g-2">
          {categories &&
            categories?.map((category) => (
              <div className="col-6 col-lg-3" key={category?.id}>
                <Link
                  to={`/products/${category?.id}`}
                  className="text-decoration-none"
                >
                  <CategoryProductCard category={category} />
                </Link>
              </div>
            ))}
        </div>
      </div>
    </Spin>
  );
};

export default Categories;
