import React, { useEffect, useState } from "react";
import NewProductCard from "../components/NewProductCard";
import { Link } from "react-router-dom";
import { getAllDataFromCollection, getFilteredData, saveData } from "../utils";
import { Spin } from "antd";
import { useAuth } from "../contexts/AuthContext";
import { useProductsStore, useUserStore } from "../app/store";
import NoProductsFoundPlaceHolder from "../assets/imgs/no-products-found.svg";

const WishList = () => {
  const [newProducts, setNewProducts] = useState([]);
  const [loading, setLoading] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const { products } = useProductsStore();
  const { user } = useUserStore();
  // const { logout, currentUser } = useAuth();
  const { currentUser } = useAuth();

  useEffect(() => {
    // fetchUserDetails();
    return () => fetchUserDetails();
  }, []);
console.log(currentUser,"user")
  useEffect(() => {
    if (currentUser&& user?.id) {
      getNewProducts();
    }
  }, [user]);

  const { updateUser } = useUserStore();

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      if (currentUser?.uid) {
        let data = await getFilteredData(
          "users",
          "email",
          "==",
          currentUser?.email
        );
        let userDetail = data[0];
        setUserDetails(userDetail);
        updateUser(userDetail);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getNewProducts = async () => {
    try {
      setLoading(true);
      // let response = await getAllDataFromCollection("products");
      let response = [...products];
      console.log("products :: ", response);
      setLoading(false);
      let filtereProducts = response?.filter((product) =>
        user?.wishlist?.includes(product?.id)
      );
      setNewProducts(filtereProducts);
      getCategories(filtereProducts);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getCategories = async (filtereProducts = []) => {
    try {
      let response = await getAllDataFromCollection("categories");
      if (response) {
        const caregoryProducts = filtereProducts?.map((pro) => {
          const findcategory = response?.find(
            (cat) => cat?.id === pro?.category
          );
          if (findcategory) {
            pro.categoryName = findcategory?.name;
          } else {
            pro.categoryName = "N/A";
          }
          return pro;
        });
        setNewProducts([...caregoryProducts]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const likeProduct = (item, index) => {
    const cProductItems = newProducts?.filter((p) => p?.id !== item?.id);
    setNewProducts([...cProductItems]);
  };

  return (
    <Spin spinning={loading}>
      <div className="pb-4 what-is-new container mt-[7rem]">
        <h1 className="pt-[32px] pb-[16px] main-heading">Wishlist</h1>
        <div className="px-2 m-0 row g-2">
          {newProducts &&
            newProducts?.map((product, index) => {
              if (index < 12)
                return (
                  <div className="col-6 col-lg-3 col-xxl-2" key={product?.id}>
                    <div className="text-decoration-none h-full">
                      <NewProductCard
                        product={product}
                        uid={currentUser?.uid || ""}
                        likeProduct={likeProduct}
                        index={index}
                        userDetail={userDetails}
                        fetchProducts={getNewProducts}
                        fetchUserDetails={fetchUserDetails}
                      />
                    </div>
                  </div>
                );
            })}
        </div>
        {!newProducts?.length && !loading && (
            <div className="text-center  mt-4">
              <img src={NoProductsFoundPlaceHolder} />
            </div>
         
        )}
      </div>
    </Spin>
  );
};

export default WishList;
